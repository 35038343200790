import React, { lazy } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Loadable from 'ui-component/Loadable';
import PropTypes from 'prop-types';
import DeleteIcon from 'assets/images/deleteIcon.png';

const ConfirmationContent = Loadable(lazy(() => import('views/pages/deleteModal')));
const AdminLongTermAndCare = Loadable(lazy(() => import('views/pages/formSubmission/longtermAndCare/viewLongtermAndCare')));
const RiskHedgeForm = Loadable(lazy(() => import('views/pages/riskHedge/form')));
const MultiDynamicCategory = Loadable(lazy(() => import('views/pages/DND/MultiDynamicCategory/dnd')));
const Goals = Loadable(lazy(() => import('views/pages/Goals')));
const HouseholdForm = Loadable(lazy(() => import('views/pages/houseHold/createHouseholdForm')));
const ClientForm = Loadable(lazy(() => import('views/pages/houseHold/createForm')));
const UserForm = Loadable(lazy(() => import('views/pages/UserManagement/createAndEditForm')));
const CalculateBody = Loadable(lazy(() => import('ui-component/Charts/calculatebody')));
const TenantForm = Loadable(lazy(() => import('views/pages/tenants/createAndEditForm')));
const CashFlowTable = Loadable(lazy(() => import('ui-component/Charts/cashFlowTable')));
const PlanOptimizer = Loadable(lazy(() => import('ui-component/FinancialTool/plan-optimizer')));
const Splash = Loadable(lazy(() => import('views/pages/splashScreen/uploadPDF')));
const ViewPermanentLifeInsurance = Loadable(lazy(() => import('views/pages/formSubmission/permanentLifeInsurance/view')));
const ApproveAndEditRequest = Loadable(lazy(() => import('views/pages/formSubmission/lifeInsurance/approveAndEditRequest.js')));

const BasicModal = ({
  deleteRecord,
  modalText,
  rowIndexStatus,
  handleCheckboxChange,
  changeStatus,
  isDelete,
  open,
  modalClose,
  isLogout,
  handleLogout,
  isDND,
  chartData,
  modalOpen,
  isRiskHedge,
  width,
  maxWidth,
  isClient,
  setOpen,
  editId,
  checked,
  resetChecked,
  extraProps,
  id,
  setLoader,
  url,
  setKey,
  modalCashFlowOpen,
  form,
  pagination,
  houseHold,
  edit,
  year,
  title,
  disableBackdrop,
  addpdfurl,
  filters,
  userIds,
  pdfId
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:
      typeof width === 'object'
        ? width
        : {
            xs: width ? width : '80%',
            sm: width ? width : '70%',
            md: width ? width : '60%',
            lg: width ? width : '50%',
            xl: width ? width : '40%'
          },
    maxHeight: { xs: '90%', md: '95%' },
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    outline: 'none',
    overflowY: 'auto', // Enable vertical scrollbar when content exceeds the height
    padding: { xs: '25px', md: '40px' },
    ...(maxWidth && { maxWidth }),
    ...(isDND === 'pdf-splash' && {
      maxHeight: '100%',
      border: 'none',
      backgroundColor: 'unset',
      padding: '0px',
      '& #close-icon': {
        position: 'absolute',
        right: '0px',
        top: '-5px',
        color: '#fff'
      }
    })
  };
  return (
    <div>
      <Modal
        className="modalbody"
        open={Boolean(open)}
        onClose={(_, reason) => {
          if (reason && disableBackdrop && ['escapeKeyDown', 'backdropClick'].includes(reason)) return;
          modalClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            id="close-icon"
            aria-label="close"
            onClick={modalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              zIndex: 999
            }}
          >
            <CloseIcon />
          </IconButton>
          {changeStatus === 'user_edit' ? (
            <UserForm modalClose={modalClose} isEdit={editId} {...extraProps} />
          ) : changeStatus === 'edit_tenant' ? (
            <TenantForm modalClose={modalClose} isEdit={editId} {...extraProps} />
          ) : changeStatus === 'activate_user' ? (
            <ConfirmationContent
              title={rowIndexStatus == true ? 'Deactivate User?' : 'Activate User?'}
              message={
                rowIndexStatus == true ? 'Are you sure you want to deactivate this user?' : 'Are you sure you want to activate this user?'
              }
              onClose={modalClose}
              icon={DeleteIcon}
              onConfirm={handleCheckboxChange}
              confirmationButton={rowIndexStatus == true ? 'Deactivate' : 'Activate'}
              cancelButton={'Cancel'}
            />
          ) : changeStatus === 'change_tenant_status' ? (
            <ConfirmationContent
              title={rowIndexStatus == 1 ? 'Deactivate Tenant?' : 'Activate Tenant?'}
              message={
                rowIndexStatus == 1 ? 'Are you sure you want to deactivate this tenant?' : 'Are you sure you want to activate this tenant?'
              }
              onClose={modalClose}
              icon={DeleteIcon}
              onConfirm={handleCheckboxChange}
              confirmationButton={rowIndexStatus == 1 ? 'Deactivate' : 'Activate'}
              cancelButton={'Cancel'}
            />
          ) : changeStatus === 'resubmit_form' ? (
            <ConfirmationContent
              title="Resubmit Form?"
              message="Are you sure you want user to resubmit the form?"
              onClose={modalClose}
              icon={DeleteIcon}
              onConfirm={deleteRecord}
              confirmationButton={'Resubmit'}
              cancelButton={'Cancel'}
            />
          ) : changeStatus === 'edit_approval' ? (
            <ApproveAndEditRequest
              id={id}
              setOpen={setOpen}
              setLoader={setLoader}
              url={url}
              setKey={setKey}
              form={form}
              pagination={pagination}
            />
          ) : changeStatus === 'view_long_term_care_and_medicare' ? (
            <AdminLongTermAndCare id={id} />
          ) : changeStatus === 'view_permanent_life_insurance' ? (
            <ViewPermanentLifeInsurance id={id} />
          ) : // changeStatus === 'view_life_insurance' ? (
          //   <ViewLifeInsurance id={id} />
          // ):
          changeStatus === 'delete_all' ? (
            <ConfirmationContent
              title="Delete Households!"
              message={modalText}
              onClose={modalClose}
              icon={DeleteIcon}
              onConfirm={deleteRecord}
              confirmationButton={'Delete'}
              cancelButton={'Cancel'}
            />
          ) : changeStatus === 'delete_financial_plan' ? (
            <ConfirmationContent
              title="Are you sure you want to delete this?"
              message={modalText}
              onClose={modalClose}
              icon={DeleteIcon}
              onConfirm={deleteRecord}
              confirmationButton={'Delete'}
              cancelButton={'Cancel'}
            />
          ) : changeStatus === 'delete_all_clients' ? (
            <ConfirmationContent
              title="Delete Clients!"
              message={modalText}
              onClose={modalClose}
              icon={DeleteIcon}
              onConfirm={deleteRecord}
              confirmationButton={'Delete'}
              cancelButton={'Cancel'}
            />
          ) : changeStatus === 'multipleDelete' ? (
            <ConfirmationContent
              title="Delete Tenants!"
              message={modalText}
              onClose={modalClose}
              icon={DeleteIcon}
              onConfirm={deleteRecord}
              confirmationButton={'Delete'}
              cancelButton={'Cancel'}
            />
          ) : changeStatus === 'single_delete' ? (
            <ConfirmationContent
              title="Delete Household!"
              message={modalText}
              onClose={modalClose}
              icon={DeleteIcon}
              onConfirm={deleteRecord}
              confirmationButton={'Delete'}
              cancelButton={'Cancel'}
            />
          ) : changeStatus === 'single_delete_client' ? (
            <ConfirmationContent
              title="Delete Client!"
              message={modalText}
              onClose={modalClose}
              icon={DeleteIcon}
              onConfirm={deleteRecord}
              confirmationButton={'Delete'}
              cancelButton={'Cancel'}
            />
          ) : changeStatus === 'delete_tenant' ? (
            <ConfirmationContent
              title="Delete Tenant!"
              message={modalText}
              onClose={modalClose}
              icon={DeleteIcon}
              onConfirm={deleteRecord}
              confirmationButton={'Delete'}
              cancelButton={'Cancel'}
            />
          ) : changeStatus ? (
            <ConfirmationContent
              title="Alert"
              message={modalText}
              onClose={modalClose}
              onConfirm={deleteRecord}
              confirmationButton={'Yes'}
              cancelButton={'No'}
            />
          ) : isDelete ? (
            <ConfirmationContent
              title="Delete Client!"
              message={modalText}
              icon={DeleteIcon}
              onClose={modalClose}
              onConfirm={deleteRecord}
              confirmationButton={'Delete'}
              cancelButton={'Cancel'}
            />
          ) : houseHold ? (
            !edit ? (
              <ClientForm modalClose={modalClose} houseHold={houseHold} />
            ) : (
              <HouseholdForm modalClose={modalClose} houseHold={houseHold} pagination={pagination} setLoader={setLoader} edit={edit} />
            )
          ) : isClient === 'Client management' ? (
            <HouseholdForm modalClose={() => setOpen(false)} />
          ) : isClient === 'User management' ? (
            <UserForm modalClose={() => setOpen(false)} />
          ) : isClient === 'Tenants' ? (
            <TenantForm modalClose={() => setOpen(false)} />
          ) : isLogout ? (
            <ConfirmationContent
              title="Logout"
              message="Are you sure wants to logout?"
              onClose={modalClose}
              onConfirm={handleLogout}
              confirmationButton={'Yes'}
              cancelButton={'No'}
            />
          ) : isDND ? (
            isDND === 'Goals' ? (
              <Goals modalClose={modalClose} />
            ) : isDND === 'PlanOptimizer' ? (
              <PlanOptimizer modalOpen={modalOpen} />
            ) : isDND === 'CalculateOptimizer' ? (
              <CalculateBody chartData={chartData} modalClose={modalClose} />
            ) : isDND === 'cashFlowTable' ? (
              <CashFlowTable
                modalClose={modalClose}
                modalCashFlowOpen={modalCashFlowOpen}
                title={title}
                year={year}
                userIds={userIds}
                filters={filters}
              />
            ) : isDND === 'splash' ? (
              <Splash addpdfurl={addpdfurl} pdfId={pdfId} modalClose={modalClose} />
            ) : isDND === 'pdf-splash' ? (
              <embed src={addpdfurl} title="PDF Preview" width="100%" height="800px" style={{ border: 'none' }} />
            ) : (
              <MultiDynamicCategory
                id={isDND}
                modalClose={modalClose}
                lifeEvent={isDND === 'LifeEvents' ? true : false}
                checked={checked}
                resetChecked={resetChecked}
              />
            )
          ) : isRiskHedge ? (
            <RiskHedgeForm />
          ) : (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

BasicModal.propTypes = {
  isDelete: PropTypes.bool,
  isLogout: PropTypes.bool,
  open: PropTypes.bool,
  modalClose: PropTypes.func,
  handleLogout: PropTypes.func,
  isDND: PropTypes.string,
  isRiskHedge: PropTypes.bool
};

export default BasicModal;
